import { reactive } from "vue";

const theme = reactive({
    agentLogo: "Footer.png",
    devLogo: "Header.png",
    apiUrl: "https://api.metaluxe.com.au/",
    bunnyApiUrl: "https://api.bunny.net/",
    accessKey: "07aeb6d1-92b0-48fc-9fa3-e202bc32d8753a016979-9f0b-4b1d-8027-5575c13bad47",
    streamToken: "9a55257e-4ace-452b-954f-d79c67c62b1f",
    videoLibraryId: "128382",
    streamCdnUrl: "https://vz-0fc4b22e-5dd.b-cdn.net",
    streamApiKey: "a4333c0c-b503-4e21-8d94a958fe22-b8cc-4241",
    storageApiPassword: "6a9352a7-5f82-40df-a7a097cd5f66-819c-4ae0",
    cdnUrl: "https://metavision-plural-pwa.b-cdn.net",
    devFolder: "/metavision-plural-pwa",
    googleAnalytics: "",
    videosIcon: "/img/ui-theme/videos-icon.svg",
    rendersIcon: "/img/ui-theme/renders-icon.svg",
    rangeIcon: "/img/ui-theme/range-icon.svg",
    panoramasIcon: "/img/ui-theme/panoramas-icon.svg",
    floorplansIcon: "/img/ui-theme/floorplans-icon.svg",
    dashboardMp4: "theme/dashboard/4K_dashboard.mp4",
    
    // top level menu
    assetsMenuOrder: [
        '26 Vista Showcase',
        'Florian Showcase',
        'Ritz Carlton Marina Showcase',
        'Land Subdivision Showcase',
        'PA Health Connect Showcase'
    ],

    bed1MenuOrder: [
        'videos',
        'virtual-tour',
        'balcony-views',
        'floorplans',
        'range',
        'renders',
        'pdf'
    ],
  
    bed1Asset: {
        videos: [],
        videosName: [],
        panoramas: [0],
        panoramasName: [
            'Vista 3 bed'
        ],
        panoramasUrl: [
            'https://app.cloudpano.com/tours/5phsXhF-B'
        ],
        balcony: [0],
        balconyName: [],
        balconyUrl: [ ],
        renders: [],
        rendersName: [],
        rendersUrl: [],
        floorplans: [],
        floorplansName: [],
        floorplansUrl: [],
        range: [],
        rangeName: [],
        rangeUrl: [],
        pdf: [0],
        pdfName: [],
        pdfUrl: [],
    },

    bed2Asset: {
        videos: [],
        videosName: [],
        panoramas: [0],
        panoramasName: [
            'Florian Type A'
        ],
        panoramasUrl: [
            'https://app.cloudpano.com/tours/pKlyrB_EF8KW'
        ],
        balcony: [0],
        balconyName: [],
        balconyUrl: [ ],
        renders: [],
        rendersName: [],
        rendersUrl: [],
        floorplans: [],
        floorplansName: [],
        floorplansUrl: [],
        range: [],
        rangeName: [],
        rangeUrl: [],
        pdf: [0],
        pdfName: [],
        pdfUrl: [],
    },

    bed3Asset: {
        videos: [],
        videosName: [],
        panoramas: [0],
        panoramasName: [
            'Marina Arm A'
        ],
        panoramasUrl: [
            'https://app.cloudpano.com/tours/D_0nXoSSLNt'
        ],
        balcony: [0],
        balconyName: [],
        balconyUrl: [ ],
        renders: [],
        rendersName: [],
        rendersUrl: [],
        floorplans: [],
        floorplansName: [],
        floorplansUrl: [],
        range: [],
        rangeName: [],
        rangeUrl: [],
        pdf: [0],
        pdfName: [],
        pdfUrl: [],
    },

    bed4Asset: {
        videos: [],
        videosName: [],
        panoramas: [0],
        panoramasName: [
            'Land Subdivision Demo'
        ],
        panoramasUrl: [
            'https://app.cloudpano.com/tours/2T2ciQRdd'
        ],
        balcony: [0],
        balconyName: [],
        balconyUrl: [ ],
        renders: [],
        rendersName: [],
        rendersUrl: [],
        floorplans: [],
        floorplansName: [],
        floorplansUrl: [],
        range: [],
        rangeName: [],
        rangeUrl: [],
        pdf: [0],
        pdfName: [],
        pdfUrl: [],
    },

    bed5Asset: {
        videos: [],
        videosName: [],
        panoramas: [0],
        panoramasName: [],
        panoramasUrl: [],
        balcony: [0],
        balconyName: [],
        balconyUrl: [ ],
        renders: [],
        rendersName: [],
        rendersUrl: [],
        floorplans: [],
        floorplansName: [],
        floorplansUrl: [],
        range: [],
        rangeName: [],
        rangeUrl: [],
        pdf: [0],
        pdfName: [],
        pdfUrl: [],
    },
});

export default { theme };